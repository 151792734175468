import React from 'react';
import PinDropIcon from '@mui/icons-material/PinDrop';
import SchoolIcon from '@mui/icons-material/School';
import Person2Icon from '@mui/icons-material/Person2';
import AirplanemodeActiveIcon from '@mui/icons-material/AirplanemodeActive';
import ForumIcon from '@mui/icons-material/Forum';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';

export const Why247 = [
  {
    title: 'Faster bookings',
    icon: <PinDropIcon className="mb-12 text-7xl" />,
    classes: 'bg-secondary text-black',
  },
  {
    title: '10% discount for students',
    icon: <SchoolIcon className="mb-12 text-7xl" />,
    classes: 'bg-primary text-white',
  },
  {
    title: 'Priority service for members',
    icon: <Person2Icon className="mb-12 text-7xl" />,
    classes: 'bg-primary text-white',
  },
  {
    title: 'Birmingham Airport Transfers service',
    icon: <AirplanemodeActiveIcon className="mb-12 text-7xl" />,
    classes: 'bg-secondary text-black',
  },
  {
    title: 'Text back & ring back',
    icon: <ForumIcon className="mb-12 text-7xl" />,
    classes: 'bg-gray text-white',
  },
  {
    title: 'Get a lift from your favourite pickup points',
    icon: <PinDropIcon className="mb-12 text-7xl" />,
    classes: 'bg-primary text-white',
  },
  {
    title: 'Getting you from A to B is our speciality',
    icon: <SyncAltIcon className="mb-12 text-7xl" />,
    classes: 'bg-primary text-white',
  },
  {
    title: 'Taxi firm you can rely on',
    icon: <DirectionsCarIcon className="mb-12 text-7xl" />,
    classes: 'bg-secondary text-black',
  },
];
