import Image from 'next/image';
import Link from 'next/link';
import { PrivateHireBirmingham, TaxiQuote, Why247 } from './Data';
import HomeServiceCard from '@common/cards/HomeServiceCard';

const CardsSection = () => {
  return (
    <>
      {/* <div className="grid grid-cols-1 lg:grid-cols-2 paddingClass gap-7 ">
        <div className="w-[95%]">
          <div className="my-4">
            <div>
              <div className="text-2xl font-light">
                Private Hire Taxis in <b>Birmingham</b>
              </div>
              <div className="mt-5 text-justify">
                Welcome to the 24/7 Radio Carz website, which has been created
                to make booking taxis in Birmingham a whole lot easier! We are
                the largest private hire taxi company in Birmingham, and that
                means our customers never have to wait long for a car.
                <br />
                <br />
                We provide a wide range of vehicles that are suitable for all
                occasions, ranging from executive cars to state-of-the-art mini
                buses, and also have a wide range of airport cars to make your
                journey to any airport comfortable and relaxing.
                <br />
                <br />
                If you need a Birmingham taxi quote or want to book a private
                hire car in Birmingham, our website gives you all the tools you
                need to do just that.
                <br />
                <br />
              </div>
              <div className="text-2xl font-light ">Airport transfers</div>
              <div className="mt-5 text-justify">
                The last thing you want to do come the day of your flight is
                undertake the hassle of driving to the airport. Worrying about
                getting there on time, finding (and paying for) parking and
                adding yet more wear and tear to your car simply isn’t worth it.
                <br />
                <br />
                We’ll pick you up (from multiple locations if required) and get
                you to the airport both on time and fully refreshed for your
                flight.
                <br />
                <br />
              </div>
              <div className="text-2xl font-light">Taxis for students</div>
              <div className="mt-5 text-justify">
                If you’re a student and you need a reliable, friendly and
                technology-savvy taxi company in Acocks Green, you’ve come to
                the right place. Avoid the expense and risk associated with
                street cabs and give us a call whenever you need a ride.
                <br />
                <br />
                Our drivers are experienced and we even have students within our
                team, which gives us a great understanding of student life and
                their requirements for taxis. You shouldn’t ever have to walk
                home alone or pay over the odds for a ride – get in touch today
                if you want to get around Birmingham safely.
                <br />
                <br />
              </div>
            </div>
          </div>
        </div>
        <div className="my-4">
          <div>
            <div className="text-2xl font-light text-center">
              Why Choose <b>24/7 Carz</b>
            </div>
            <div className="mt-5 leading-8 text-center">
              Based in Acocks Green, Birmingham, 24/7 Radio Carz is run by a
              team of friendly people whose mission it is to provide safe,
              reliable and comfortable taxis in Birmingham. If you need a ride,
              and you want it the easy way – choose us!
            </div>
            <div className="grid grid-cols-1 gap-4 my-5 lg:grid-cols-2">
              {Why247.map((item, index) => (
                <HomeServiceCard key={index} data={item} />
              ))}
            </div>
          </div>
        </div>
      </div> */}
      <div className="paddingClass">
        <div className="grid grid-cols-1 gap-8 md:grid-cols-3">
          <div className='flex justify-center'>

            <Link href="/birmingham-airport-transfers">
              <div className="relative w-[400px] h-[400px] ">
                <a>
                  <Image
                    layout='fill'
                    objectFit='contain'
                    quality={100}
                    src="/assets/images/BHXAirport.png"
                    alt="Airport Transfers"
                  />
                </a>
              </div>
            </Link>
          </div>
          <div className='flex justify-center'>
            <Link href="/our-fleet">
              <div className="relative w-[400px] h-[400px] ">
                <a>
                  <Image
                    layout='fill'
                    objectFit='contain'
                    quality={100}
                    src="/assets/images/privateHire.png"
                    alt="Airport Transfers"
                  />
                </a>
              </div>
            </Link>
          </div>
          <div className='flex justify-center'>
            <Link href="/student-taxi-in-solihull">
              <div className="relative w-[400px] h-[400px] ">
                <a>
                  <Image
                    layout='fill'
                    objectFit='contain'
                    quality={100}
                    src="/assets/images/TaxisForStudent.png"
                    alt="Airport Transfers"
                  />
                </a>
              </div>
            </Link>
          </div>
        </div>
        <div className="grid grid-cols-1 gap-5 my-10 lg:gap-20 md:grid-cols-2">
          <div>
            <div className="text-2xl font-light">Taxi Quote Birmingham</div>
            <div className="mt-5 text-justify">
              Before committing to a Birmingham taxi, people want an idea of
              what they will have to pay for the ride, but we’ve taken that a
              step further by offering guaranteed, accurate quotes for your
              planned journey.
              <br /> <br />
              This means you can avoid the common risk of being overcharged by
              random, unlicensed taxis that can be found on many Birmingham
            </div>
          </div>

          <div>
            <div className="text-2xl font-light ">
              Online booking and payment
            </div>
            <div className="mt-5 text-justify">
              Few people carry cash these days, which is why you can book your
              Birmingham taxi with us online and pay for it there and then by
              credit or debit card. It’s the no-hassle way to ensure you have a
              car ready and waiting for you when you need it, without the worry
              of having the correct change or needing to visit a cashpoint.
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CardsSection;
