
require("./../node_modules/@module-federation/nextjs-mf/src/include-defaults");
import {
  JsonLdFaq,
  JsonLdOrg,
  JsonLdReview,
  JsonLdWebsite,
} from '@components/config/SEO';
const Home = dynamic(() => import('@components/pagesComp/home/Index'), {
  ssr: false,
});
import { DefaultSeo, NextSeo } from 'next-seo';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import SEO from '../next-seo.config';
import HomeContents from '@pages/home/HomeContents';
import CardsSection from '@pages/home/CardsSection';

export default function Index() {
  return (
    <div>
      <NextSeo canonical="https://www.247radiocarz.co.uk" />
      <JsonLdWebsite />
      <JsonLdOrg />
      <JsonLdReview />
      {/* <JsonLdFaq /> */}
      <DefaultSeo {...SEO} />
      <Home />
      <HomeContents />
      <CardsSection />
    </div>
  );
}
