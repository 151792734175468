export default {
  title: 'Stress Free Journey from School Runs to Business Meetings',
  description:
    'Need a ride to the airport or an efficient way of getting around town? Our 24/7 taxi service has you covered! From school runs and business meetings, our team of experienced drivers ensures reliable transportation with prompt pick-up times. Enjoy easy commuting in comfort wherever your destination may be!',
  openGraph: {
    type: 'website',
    locale: 'en-GB',
    url: 'https://www.247radiocarz.co.uk/',
    siteName: '247 Radio Carz',
    title: 'Stress Free Journey from School Runs to Business Meetings',
    description:
      'Need a ride to the airport or an efficient way of getting around town? Our 24/7 taxi service has you covered! From school runs and business meetings, our team of experienced drivers ensures reliable transportation with prompt pick-up times. Enjoy easy commuting in comfort wherever your destination may be.',
    images: [
      {
        url: 'https://www.247radiocarz.co.uk/assets/images/logo.png',
        width: 800,
        height: 600,
        alt: '247 Radio Carz',
        type: 'image/png',
      },
    ],
  },
  twitter: {
    handle: '@handle',
    site: '@site',
    cardType: 'summary_large_image',
  },
};
